import React from 'react';
import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="App-message">
          <h1>AUTOMATE COMMERCE</h1>
          <p>Automating eCommerce</p>
          <a
            className="App-link"
            href="https://automatecommerce.zendesk.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Support
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
